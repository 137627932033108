import gsap from "gsap"
import { useGSAP } from "@gsap/react"
import { rightImg, watchImg } from "../utils"
import VideoCarousel from "./VideoCarousel"


const Highlights = () => {
  useGSAP(() => {
    gsap.to('#title', { opacity: 1, y: 0})
  }, [])

  return (
    <section id="highlights" className="w-screen overflow-hidden h-full common-padding bg-zinc">
      <div className="screen-max-width">
        <div className="mb-12 w-full flex items-end justify-between">
          <h1 id="title" className="section-heading">Websites made by Cam.
          </h1>
        </div>

        <VideoCarousel />

      </div>
    </section>
  )
}

export default Highlights