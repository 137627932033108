import gsap from "gsap";
import { useRef, useEffect, useState } from "react";
import { heroVideo, smallHeroVideo } from "../utils";

const Hero = () => {
  const [videoSrc, setVideoSrc] = useState(
    window.innerWidth < 760 ? smallHeroVideo : heroVideo
  );

  // Ref to scope the GSAP animations to this component
  const heroRef = useRef(null);

  const handleVideoSrcSet = () => {
    if (window.innerWidth < 760) {
      setVideoSrc(smallHeroVideo);
    } else {
      setVideoSrc(heroVideo);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleVideoSrcSet);

    return () => {
      window.removeEventListener("resize", handleVideoSrcSet);
    };
  }, []);

  useEffect(() => {
    // gsap.context to scope animations to the heroRef element
    const ctx = gsap.context(() => {
      gsap.to("#hero", { opacity: 1, delay: 1.5 });
      gsap.to("#cta", { opacity: 1, y: -20, delay: 2.5 });
    }, heroRef);

    // Cleanup the context to prevent memory leaks
    return () => ctx.revert();
  }, []);

  return (
    <section
      ref={heroRef}
      className="w-full h-screen bg-black relative overflow-hidden flex flex-col justify-center items-center"
    >
      {/* Container for Video */}
      <div id='hero' className="w-full h-full flex justify-center items-center">
        <video
          className="w-full h-full object-cover pointer-events-none"
          autoPlay
          muted
          playsInline
          key={videoSrc}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
      </div>

      {/* Call to Action Button */}
      <div
  id="cta"
  className="flex flex-col items-center opacity-0 transform translate-y-20 absolute bottom-8 z-10"
>
  {/* Update the button with Tailwind utility classes */}
  <a
    href="#highlights"
    className="bg-black text-white font-mono px-4 py-2 rounded-full transition duration-300 hover:bg-gray-800 hover:text-orange-500"
  >
    Make Me One
  </a>
</div>

    </section>
  );
};

export default Hero;
