import React from 'react'
import { footerLinks } from '../constants'
import classNames from 'classnames'

const Footer = () => {
  return (
    <footer id='footer' className='py-5 sm:px-10 px-5'>
      <div className='screen-max-width'>
        <div>
            <p className="font-semibold text-gray text-xs">
                More ways to reach me:
                <span className='underline text-blue'>
                     Find Me {' '}
                </span>
                or
                <span className='underline text-blue'>
                     Other
                </span>{' '}
            </p>
            <p className='font-semibold text-gray text-xs'>
                Or call me
            </p>
        </div>
        <div className='bg-neutral-700 my-5 h-[1px] 
        w-full' />
        <div className='flex md:flex-row flex-col 
        md:itmes-center justify-between'>
            <p className='font-semibold text-gray text-xs'>
                Copyright @ 2024 CamTex Inc.</p>
                <div className='flex'>
                    {footerLinks.map((link, i) => (
                        <p key={link} className='font-semibold text-gray text-xs'>
                            {link}{' '}
                            {i !== footerLinks.length - 1 && (
                                <span className='=mx-2'> | </span>
                            )}
                            </p>
                        ))}
            </div>
        </div>
      </div> 
    </footer>
  )
}

export default Footer